import './App.css';
import Minter from './Minter'

function App() {
  return (
    <div className="App">
      <div class="page-width banner-photo">
        <div class="page-width">
          <div class="promo-image-parent">
            <a class="Logo-section">MoodRollers</a>
          </div>
          </div>
          
      </div>

      <div class="page-width image-section"><img src="Banner.jpg" class="desktop-img" loading="lazy"  alt=""></img><img class="mobile-img" src="Banner-mobile.png" loading="lazy"  alt=""></img></div>
      <div class="solid-banner">
        <div>
          <h1>Official MoodRollers Discounted Merch Shop</h1>
        </div>
      </div>
      
      <div class="page-width main-content wf-section">
        <div class="redirect-section">
        <Minter></Minter>
        </div>
      </div>
      <div class="footer wf-section">
        <div class="footer-main-content">
          <p class="powered-by-text">Powered by Top Drawer Merch</p>
        </div>
        
      </div>
    </div>
  );
}

export default App;
